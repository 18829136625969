* {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    background: 0 0;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    outline: 0;
    overflow-x: hidden;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    line-height: 1.5;
    overflow-x: hidden;
    text-decoration: none;
}
::-webkit-inner-spin-button,
  input[type=date]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="text"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
}

input[type="button"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
input[type="number"] {
    -moz-appearance: textfield;
}
ol,
ul {
    list-style: none;
}
blockquote {
    quotes: none;
}
blockquote:after,
blockquote:before {
    content: "";
    content: none;
}
del {
    text-decoration: line-through;
}
address,
article,
aside,
footer,
header,
nav,
section {
    display: block;
}
body {
    font-family: poppins-regular;
    font-size: 18px;
    color: #545454;
    /* overflow-x: hidden; */
    min-width: 320px;
    /* -ms-overflow-style: none; */
    /* scrollbar-width: none; */
}
/* body::-webkit-scrollbar {
    display: none;
} */
img {
    width: 100%;
    display: block;
}
span {
    display: block;
}
.reason_for_removal {
    font-family: poppins-regular;
    background: linear-gradient(90deg, #f4f4f4 0%, #f4f8fd 100%);
    width: 100%;
    resize: none;
    padding: 15px;
    font-size: 14px;
}

input,
select,
textarea,
p {
    font-family: poppins-regular;
}
a {
    display: inline-block;
    text-decoration: none;
}
html {
    /* overflow: scroll; */
    scroll-behavior: smooth !important;
}
/* @font-face {
	font-display: auto;
	font-family: gordita_regular;
	src: url(../fonts/gordita/GorditaRegular.woff) format("woff");
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-display: auto;
	font-family: gordita_medium;
	src: url(../fonts/gordita//GorditaMedium.woff) format("woff");
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: gordita_bold;
	src: url(../fonts/gordita/GorditaBold.woff) format("woff");
	font-weight: 400;
	font-style: normal;
} */
@font-face {
    font-display: auto;
    font-family: poppins-regular;
    src: url(../fonts/poppins/poppins-regular-webfont.woff) format("woff");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-display: auto;
    font-family: poppins-medium;
    src: url(../fonts/poppins/poppins-medium-webfont.woff) format("woff");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-display: auto;
    font-family: poppins-semibold;
    src: url(../fonts/poppins/poppins-semibold-webfont.woff) format("woff");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-display: auto;
    font-family: poppins-bold;
    src: url(../fonts/poppins/poppins-bold-webfont.woff) format("woff");
    font-weight: 400;
    font-style: normal;
}
#search{
 
    margin-left: 280px;
    padding-left:20px;
    padding-right: 20px;
    padding-top: 18px;
}
#center {
    padding-top: 20px;
    margin-left: 280px;
    padding-left: 20px;
    padding-right: 20px;
   
}

#select-item:first-child {
    display: none;
}

select {
    appearance: none;
    background-color: transparent;
    border: none;
    padding: 0 1em 0 0;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    z-index: 1;
}
span {
    display: block;
}
small {
    display: block;
}
body {
    background: #eff3f9;
}
body::-webkit-scrollbar{
    display: none;
}
#profile{
    margin-top: 10px;
    margin-left: 660px;
    margin-right: 30px;
}